import { render, staticRenderFns } from "./SubscriptionProducts.vue?vue&type=template&id=318f16fc&scoped=true&"
import script from "./SubscriptionProducts.vue?vue&type=script&lang=ts&"
export * from "./SubscriptionProducts.vue?vue&type=script&lang=ts&"
import style0 from "./SubscriptionProducts.vue?vue&type=style&index=0&id=318f16fc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "318f16fc",
  null
  
)

export default component.exports