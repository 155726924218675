




















import { Api, ImageUpload, StoredImage, StripeInvoiceAccountRegion, SubscriptionProductInfo, SubscriptionProductType } from '@/edshed-common/api'
import ComponentHelperBase from '@/edshed-common/mixins/ComponentHelperBase'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import ImageInput from '@/edshed-common/components/ImageInput.vue'

@Component({
  name: 'AddSubscriptionProduct',
  components: { ImageInput }
})
export default class AddSubscriptionProduct extends Mixins(ComponentHelperBase) {
  @Prop({ default: null })
  editProduct!: SubscriptionProductInfo | null

  StripeInvoiceAccountRegion = StripeInvoiceAccountRegion

  SubscriptionProductType = SubscriptionProductType

  title: string = ''

  product: SubscriptionProductType | null = null

  image: ImageUpload | null = null

  imageRecordDummy: { image: StoredImage | null, new_image: ImageUpload | null } = { image: null, new_image: null }

  @Watch('needsSave', { immediate: true })
  needsSaveChanged (val: boolean) {
    this.$emit('needs-save', val)
  }

  get needsSave () {
    if (!this.editProduct) {
      return true
    }

    if (this.title !== this.editProduct.title) {
      return true
    }

    if (this.imageRecordDummy.new_image !== null) {
      return true
    }

    return false
  }

  mounted () {
    this.populateFields()
  }

  populateFields () {
    if (this.editProduct) {
      this.title = this.editProduct.title
      this.product = this.editProduct.product_type
      this.imageRecordDummy = { image: this.editProduct.image, new_image: null }
    }
  }

  imageSelected (image: ImageUpload) {
    this.image = image
  }

  async saveProduct () {
    try {
      if (this.title.trim() === '') {
        this.$buefy.toast.open({
          message: 'Title required',
          position: 'is-bottom',
          type: 'is-danger',
          container: '.toastContainer'
        })
        return
      }

      if (this.editProduct) {
        const product = await Api.updateSubscriptionProduct(this.editProduct.id, {
          title: this.title,
          new_image: this.imageRecordDummy.new_image ?? undefined
        })

        this.$emit('updated', product)
      } else {
        if (!this.product) {
          this.$buefy.toast.open({
            message: 'Product type required',
            position: 'is-bottom',
            type: 'is-danger',
            container: '.toastContainer'
          })
          return
        }

        if (!this.imageRecordDummy.new_image) {
          this.$buefy.toast.open({
            message: 'Product image required',
            position: 'is-bottom',
            type: 'is-danger',
            container: '.toastContainer'
          })
          return
        }

        const product = await Api.addSubscriptionProduct({
          title: this.title,
          product_type: this.product,
          new_image: this.imageRecordDummy.new_image
        })

        this.$emit('created', product)
      }
    } catch (err) {
      console.log(err)

      this.$buefy.toast.open({
        message: 'Could not save data',
        position: 'is-bottom',
        type: 'is-danger',
        container: '.toastContainer'
      })
    }
  }
}
