




































































































































import { Api, TableState, SubscriptionProductInfo, PagedResults } from '@/edshed-common/api'
import Component from 'vue-class-component'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Mixins } from 'vue-property-decorator'
import AddSubscriptionProduct from './views/components/AddSubscriptionProduct.vue'

@Component({
  name: 'SubscriptionProducts',
  components: { AddSubscriptionProduct }
})
export default class SubscriptionProducts extends Mixins(ComponentHelper) {
  loading: boolean = false
  isAddingProduct: boolean = false

  productToEdit: SubscriptionProductInfo | null = null

  products: PagedResults<SubscriptionProductInfo> = {
    items: [],
    total: 0
  }

  searchTerm: string = ''

  table: TableState = {
    page: 1,
    perPage: 10,
    sort: 'id',
    dir: 'asc',
    term: ''
  }

  needsSave: boolean = false

  async mounted () {
    this.products = await Api.getSubscriptionProducts({
      skip: (this.table.page - 1) * this.table.perPage,
      take: this.table.perPage,
      sort: this.table.sort,
      dir: this.table.dir,
      term: this.table.term
    })
  }

  get filteredProducts (): SubscriptionProductInfo[] {
    if (this.searchTerm.trim() === '') {
      return this.products.items
    }

    const term = this.searchTerm.toLowerCase()

    return this.products.items.filter(c => c.title.toLowerCase().includes(term))
  }

  get isSuperDuperUser () {
    return this.$store.state.user.superduperuser
  }

  closeAddModal () {
    this.isAddingProduct = false
  }

  closeEditModal () {
    this.productToEdit = null
  }

  startAddingProduct () {
    this.isAddingProduct = true
    this.productToEdit = null
  }

  startEditingProduct (product: SubscriptionProductInfo) {
    this.isAddingProduct = false
    this.productToEdit = product
  }

  addProduct () {
    (this.$refs.addProduct as AddSubscriptionProduct).saveProduct()
  }

  updateProduct () {
    (this.$refs.editProduct as AddSubscriptionProduct).saveProduct()
  }

  productCreated (product: SubscriptionProductInfo) {
    const newProducts = this.products.items
    newProducts.unshift(product)

    if (newProducts.length > this.table.perPage) {
      newProducts.pop()
    }

    this.products = {
      items: newProducts,
      total: this.products.total + 1
    }

    this.closeAddModal()
  }

  productUpdated (product: SubscriptionProductInfo) {
    const existingProduct = this.products.items.find(p => p.id === product.id)

    if (existingProduct) {
      Object.assign(existingProduct, product)
    }

    this.closeEditModal()
  }

  showGridClicked (product: SubscriptionProductInfo) {
    this.$router.push(`/subscription-products/${product.id}`)
  }
}

